import React from "react"
import { Link } from "gatsby"
import speaker1 from "../../assets/images/speaker/speaker1.jpg"
import speaker2 from "../../assets/images/speaker/speaker2.jpg"
import speaker3 from "../../assets/images/speaker/speaker3.jpg"
import speaker4 from "../../assets/images/speaker/speaker4.jpg"
import speaker5 from "../../assets/images/speaker/speaker5.jpg"
import speaker6 from "../../assets/images/speaker/speaker6.jpg"

const EventSpeakers = () => {
  return (
    <div className="scientist-area bg-fafafb pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <h2>Event Speakers</h2>
        </div>

        <div className="row">
          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className="single-scientist-item-box">
              <div className="image">
                <img src={speaker1} alt="event" />

                <ul className="social">
                  <li>
                    <Link
                      to="https://www.facebook.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://twitter.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="content">
                <h3>Merv Adrian</h3>
                <span>Data Management</span>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className="single-scientist-item-box">
              <div className="image">
                <img src={speaker2} alt="event" />

                <ul className="social">
                  <li>
                    <Link
                      to="https://www.facebook.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://twitter.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="content">
                <h3>Kirk Borne</h3>
                <span>Data Scientist</span>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className="single-scientist-item-box">
              <div className="image">
                <img src={speaker3} alt="event" />

                <ul className="social">
                  <li>
                    <Link
                      to="https://www.facebook.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://twitter.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="content">
                <h3>Carla Gentry</h3>
                <span>Analytical Solutions</span>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className="single-scientist-item-box">
              <div className="image">
                <img src={speaker4} alt="event" />

                <ul className="social">
                  <li>
                    <Link
                      to="https://www.facebook.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://twitter.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="content">
                <h3>Marie Curie</h3>
                <span>Data Scientist</span>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className="single-scientist-item-box">
              <div className="image">
                <img src={speaker5} alt="event" />

                <ul className="social">
                  <li>
                    <Link
                      to="https://www.facebook.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://twitter.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="content">
                <h3>Alina Sophia</h3>
                <span>Data Management</span>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className="single-scientist-item-box">
              <div className="image">
                <img src={speaker6} alt="event" />

                <ul className="social">
                  <li>
                    <Link
                      to="https://www.facebook.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://twitter.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bxl-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="content">
                <h3>Liam Oliver</h3>
                <span>Data Scientist</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventSpeakers
